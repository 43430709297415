import { useMemo, useEffect, useState, useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  mapFromIndividualRequest,
  mapFromBusinessRequest,
} from '../../utils/beneficiaries'
import { SECTION_BASE_PATHS } from '../../utils/general'

// Material
import { styled } from '@mui/material/styles'

// Services
import { getBeneficiaryById } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'
import { listKyx, updateStatus } from '../../services/kyxService'
import { getRoles } from '../../services/authenticationService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import View from '../../components/crud/view'
import Button from 'src/components/Button'
import {MenuItem, TextField} from "@mui/material";

// Constants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Main
const StyledFormField = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}))
const StyledFieldLabel = styled('span')(({ theme }) => ({
  color: theme.palette.blue.primary,
}))
const StyledReadOnlyFieldValue = styled('span')(({ theme }) => ({
  color: theme.palette.grey.secondary,
  textAlign: 'right',
}))
const StyledDivider = styled('hr')(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  marginBottom: theme.spacing(4),
}))
const StyledModalFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export default function BeneficiaryDetailPage() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [kyx, setKyx] = useState({})
  const [stepReason, setStepReason] = useState({})
  const rejectReasons = [
    {label: "ALERT", value: "ALERT" },
    {label: "BAD QUALITY", value: "BAD_QUALITY" },
    {label: "BLACK WHITE", value: "BLACK_WHITE" },
    {label: "CHIP MISSING", value: "CHIP_MISSING" },
    {label: "COMPLY ADVANTAGE MATCH", value: "COMPLY_ADVANTAGE_MATCH"},
    {label: "DIFFERENT PERSON", value: "DIFFERENT_PERSON" },
    {label: "DIGITAL COPY", value: "DIGITAL_COPY" },
    {label: "DIGITAL MANIPULATION", value: "DIGITAL_MANIPULATION" },
    {label: "DOCUMENT EXPIRY WITHIN CONFIGURED LIMIT", value: "DOCUMENT_EXPIRY_WITHIN_CONFIGURED_LIMIT" },
    {label: "EXTRACTION NOT DONE", value: "EXTRACTION_NOT_DONE" },
    {label: "FAKE", value: "FAKE" },
    {label: "GHOST IMAGE DIFFERENT", value: "GHOST_IMAGE_DIFFERENT" },
    {label: "INVALID MERCHANT SETTINGS", value: "INVALID_MERCHANT_SETTINGS" },
    {label: "LEGAL REP NAME MISMATCH", value: "LEGAL_REP_NAME_MISMATCH" },
    {label: "LIVENESS UNDETERMINED", value: "LIVENESS_UNDETERMINED" },
    {label: "LOW QUALITY DOCUMENT", value: "LOW_QUALITY_DOCUMENT" },
    {label: "MANIPULATED DOCUMENT", value: "MANIPULATED_DOCUMENT" },
    {label: "MISMATCH FRONT BACK", value: "MISMATCH_FRONT_BACK" },
    {label: "MISMATCH HRZ MRZ DATA", value: "MISMATCH_HRZ_MRZ_DATA" },
    {label: "MISMATCHING DATA REPEATED FACE", value: "MISMATCHING_DATA_REPEATED_FACE" },
    {label: "MISMATCHING DATAPOINTS", value: "MISMATCHING_DATAPOINTS" },
    {label: "MISSING MANDATORY DATAPOINTS", value: "MISSING_MANDATORY_DATAPOINTS" },
    {label: "MISSING PAGE", value: "MISSING_PAGE" },
    {label: "MISSING SIGNATURE", value: "MISSING_SIGNATURE" },
    {label: "MRZ CHECKSUM", value: "MRZ_CHECKSUM" },
    {label: "NAME MISMATCH", value: "NAME_MISMATCH" },
    {label: "NFC CERTIFICATE", value: "NFC_CERTIFICATE" },
    {label: "NO VALID ID CREDENTIAL", value: "NO_VALID_ID_CREDENTIAL" },
    {label: "NOT A DOCUMENT", value: "NOT_A_DOCUMENT" },
    {label: "NOT ENOUGH DATA", value: "NOT_ENOUGH_DATA" },
    {label: "NOT UPLOADED", value: "NOT_UPLOADED" },
    {label: "OK", value: "OK" },
    {label: "OTHER", value: "OTHER_REJECTION"},
    {label: "PASSED", value: "PASSED" },
    {label: "PHOTOCOPY", value: "PHOTOCOPY" },
    {label: "POLITICALLY EXPOSED PERSON", value: "POLITICALLY_EXPOSED_PERSON"},
    {label: "PRECONDITION NOT FULFILLED", value: "PRECONDITION_NOT_FULFILLED" },
    {label: "PUNCHED", value: "PUNCHED" },
    {label: "REJECTED", value: "REJECTED" },
    {label: "REPEATED FACE", value: "REPEATED_FACE" },
    {label: "SAMPLE", value: "SAMPLE" },
    {label: "SANCTION LIST MATCH", value: "SANCTION_LIST_MATCH"},
    {label: "SESSION EXPIRED", value: "SESSION_EXPIRED" },
    {label: "TAX ID MISMATCH", value: "TAX_ID_MISMATCH" },
    {label: "TECHNICAL ERROR", value: "TECHNICAL_ERROR" },
    {label: "TOKEN EXPIRED", value: "TOKEN_EXPIRED" },
    {label: "UNSUPPORTED COUNTRY", value: "UNSUPPORTED_COUNTRY" },
    {label: "UNSUPPORTED DOCUMENT TYPE", value: "UNSUPPORTED_DOCUMENT_TYPE" },
    {label: "VALIDATION FAILED", value: "VALIDATION_FAILED" },
    {label: "WARNING", value: "WARNING" },
    {label: "WATERMARK", value: "WATERMARK" }
  ]

  // Button click handlers
  const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToStatements = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)

  const goToRiskDDetails = () =>
      navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details`)

  const fetchData = useCallback(() => {
    setLoading(true)

    getBeneficiaryById(id)
      .then(beneficiary => {
        const data =
          beneficiary.type === 'INDIVIDUAL'
            ? mapFromIndividualRequest(beneficiary)
            : mapFromBusinessRequest(beneficiary)
        setData(data)
      })

    listKyx(id)
      .then(resultKyx => {
        setKyx(resultKyx)
        setLoading(false)
      })
      .catch((e) => {
        errorAlert('Error while fetching kyx')
        setLoading(false)
      })
  }, [setLoading, setKyx, id])

  const fields = useMemo(
    () => [
      {
        label: {
          displayValue: 'KYX ID',
        },
        value: {
          displayValue: kyx.id || '',
        },
      },
      {
        label: {
          displayValue: 'Status',
        },
        value: {
          displayValue: kyx.status || '',
        },
      },
      {
        label: {
          displayValue: 'Reason',
        },
        value: {
          displayValue: kyx.reason,
        },
      },
    ],
    [kyx],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isEmpty(data)) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      {
        label: 'Payments',
        value: 'payments',
        onClick: goToPaymentsPage,
      },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      },
      {
        label: 'Risk Details',
        value: 'risk-details',
        onClick: goToRiskDDetails,
      }
    ],
    selectedTab: 'kyx',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  function shouldShowButton(step) {
    return isAdmin && step.active
  }

  function onClickUpdateStatus(kyxId, workflowId, status, reason) {
    updateStatus(kyxId, workflowId, status, reason)
    window.location.reload()
  }

  return (
    <Page className='main_content' title={'Beneficiary | View'} style={{width: "70%"}}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Beneficiary | ${data.firstName || data.name} ${
            data.lastName || ''
          } - Basic Info`}
          tabsData={tabsData}
        />
        <View fields={fields} />
        <StyledDivider />
        <h3>Steps</h3>
        {(kyx?.steps?.length || 0) > 0 ? kyx.steps.map(step => {
          return (
            <div key={`${step.workflowId}-${step.type}-${step.status}`}>
              <StyledFormField>
                <StyledFieldLabel>Workflow ID</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{`${step.workflowId}`}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Type</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{step.type}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Status</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{step.status}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Reason</StyledFieldLabel>
                {shouldShowButton(step) ? (
                      <TextField
                          select
                          label="Reason"
                          name="reason"
                          value={stepReason}
                          onChange={(e) => setStepReason(e.target.value)}
                          required
                          style={{ width: '50%' }}
                      >
                        {rejectReasons.map((reason) => (
                            <MenuItem key={reason.value} value={reason.value}>
                              {reason.label}
                            </MenuItem>
                        ))}
                      </TextField>
                ) : (
                    <StyledReadOnlyFieldValue>{step.reason}</StyledReadOnlyFieldValue>
                )}
              </StyledFormField>
              {shouldShowButton(step) ? (
                  <StyledModalFooter>
                    <Button onClick={() => onClickUpdateStatus(kyx.id, step.workflowId, 'PASSED', 'OK')}>Approve</Button>
                    <Button onClick={() => onClickUpdateStatus(kyx.id, step.workflowId, 'REJECTED', stepReason)}>Reject</Button>
                    <StyledDivider />
                  </StyledModalFooter>
              ) : (
                <StyledDivider />
              )}
            </div>
          )}) : (<h2>No content found</h2>)}
        <StyledDivider />
        <h3>Errors</h3>
        {(kyx?.errors?.length || 0) > 0 ? kyx.errors.map(error => {
          return (
            <div key={`${error.workflowId}-${error.type}-${error.status}`}>
              <StyledFormField>
                <StyledFieldLabel>Workflow ID</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{`${error.workflowId}`}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Type</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{error.type}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Reason</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{error.reason}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledDivider />
            </div>
        )}) : (<h2>No content found</h2>)}
      </Loading>
    </Page>
  )
}

