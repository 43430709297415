import { listCountries } from '../services/countryService'
import { isMongoId } from '../services/stringValidation'

export const BENEFICIARY_USER_TYPES = {
  INDIVIDUAL: {
    key: 'INDIVIDUAL',
    value: 'INDIVIDUAL',
    label: 'Individual',
  },
  BUSINESS: {
    key: 'BUSINESS',
    value: 'BUSINESS',
    label: 'Business',
  },
}

export const BENEFICIARY_BUSINESS_LEGAL_STRUCTURES = {
  MEI: {
    key: 'MEI',
    value: 'MEI',
    label: 'Microempreendedor Individual'
  },
  ME: {
    key: 'ME',
    value: 'ME',
    label: 'Microempresa'
  },
  SLU: {
    key: 'SLU',
    value: 'SLU',
    label: 'Sociedade Limitada Unipessoal'
  },
  LTDA: {
    key: 'LTDA',
    value: 'LTDA',
    label: 'Sociedade Limitada'
  },
  SS: {
    key: 'SS',
    value: 'SS',
    label: 'Sociedade Simples'
  },
  SA: {
    key: 'SA',
    value: 'SA',
    label: 'Sociedade Anônima'
  }
}

export const BENEFICIARY_GENERAL_DOCUMENT_TYPES = {
  DRIVERS_LICENSE: 'DRIVERS_LICENSE',
  UTILITY_BILL: 'UTILITY_BILL',
  GOVERNMENT_ID: 'GOVERNMENT_ID',
}

export const BENEFICIARY_DOCUMENT_TYPE_MAP = {
  ID: {
    key: 'ID',
    value: 'ID',
    label: 'ID',
  },
  INCORPORATION_CERTIFICATE: {
    key: 'INCORPORATION_CERTIFICATE',
    value: 'INCORPORATION_CERTIFICATE',
    label: 'Incorporation certificate',
  },
  TAX_REGISTRATION: {
    key: 'TAX_REGISTRATION',
    value: 'TAX_REGISTRATION',
    label: 'Tax registration',
  },
  BUSINESS_BANK_ACCOUNT: {
    key: 'BUSINESS_BANK_ACCOUNT',
    value: 'BUSINESS_BANK_ACCOUNT',
    label: 'Business bank account',
  },
}

export const KYC_STATUS_MAP = {
  PENDING: {
    key: 'PENDING',
    label: 'Missing Documents',
    className: 'document_missing',
    iconifyIcon: 'ep:warning-filled',
  },
  KYC_IN_PROGRESS: {
    key: 'KYC_IN_PROGRESS',
    label: 'Documents Under Review',
    className: 'document_under_review',
    iconifyIcon: 'clarity:warning-standard-solid',
  },
  FAILED: {
    key: 'FAILED',
    label: 'Documents Rejected',
    className: 'document_rejected',
    iconifyIcon: 'ep:warning-filled',
  },
  CREATED: {
    key: 'CREATED',
    label: 'Documents Verified',
    className: 'document_verified',
    iconifyIcon: 'fluent:checkmark-circle-32-filled',
  },
  MANUAL_REVIEW: {
    key: 'MANUAL_REVIEW',
    label: 'Under manual review',
    className: 'document_under_review',
    iconifyIcon: 'clarity:warning-standard-solid',
  }
}

export const BENEFICIARY_DOCUMENT_STATUS_MAP = {
  PENDING: {
    key: 'PENDING',
    label: 'Under Review',
    className: 'document_under_review',
    iconifyIcon: 'clarity:warning-standard-solid',
  },
  SUCCEEDED: {
    key: 'SUCCEEDED',
    label: 'Confirmed',
    className: 'document_verified',
    iconifyIcon: 'fluent:checkmark-circle-32-filled',
  },
  FAILED: {
    key: 'FAILED',
    label: 'Rejected',
    className: 'document_rejected',
    iconifyIcon: 'ep:warning-filled',
  },
  MISSING: {
    key: 'MISSING',
    label: 'Missing',
    className: 'document_missing',
    iconifyIcon: 'ep:warning-filled',
  },
}

export const BENEFICIARY_DOCUMENT_TYPES = {
  [BENEFICIARY_USER_TYPES.BUSINESS.key]: [
    BENEFICIARY_DOCUMENT_TYPE_MAP.INCORPORATION_CERTIFICATE.key,
    BENEFICIARY_DOCUMENT_TYPE_MAP.TAX_REGISTRATION.key,
    BENEFICIARY_DOCUMENT_TYPE_MAP.BUSINESS_BANK_ACCOUNT.key
  ],
}

const CREATE_BENEFICIARY_STEPS = {
  shared: {
    0: ['type', 'integratorBeneficiaryId'],
    1: ['street1', 'street2', 'city', 'state', 'zipcode'],
    2: ['localTargetBankAgencyNumber', 'localTargetBankAccountNumber', 'localTargetBankName', 'localTargetAccountType', 'localTargetAccountPixKey']
  },
  admin: {
    0: ['integratorId'],
  },
  [BENEFICIARY_USER_TYPES.INDIVIDUAL.key]: {
    0: [
      'firstName',
      'lastName',
      'dateOfBirth',
      'placeOfBirth',
      'citizenship',
      'idNumber',
      'email',
      'phoneNumber',
      'profession',
      'gender',
      'maritalStatus',
      'mothersName',
      'incomeSource',
      'industry',
      'occupation',
      'wealthSource',
      'annualIncome',
    ],
    1: [],
    2: [],
    3: [],
  },
  [BENEFICIARY_USER_TYPES.BUSINESS.key]: {
    0: [
      'name',
      'dateOfIncorporation',
      'email',
      'phoneNumber',
      'idNumber',
      'description',
      'legalStructure',
    ],
    1: [],
    2: [],
    3: []
  },
}

export const BUSINESS_BENEFICIARY_CONTACT_FIELDS = {
  contactFirstName: {
    type: 'string',
    fieldType: 'text',
    label: 'First Name',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'First Name is required',
      },
      {
        type: 'required',
        message: 'First Name is required',
      },
    ],
  },
  contactLastName: {
    type: 'string',
    fieldType: 'text',
    label: 'Last Name',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Last Name is required',
      },
      {
        type: 'required',
        message: 'Last Name is required',
      },
    ],
  },
  contactDateOfBirth: {
    type: 'date',
    fieldType: 'pickdate',
    label: 'Date of Birth',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
  },
  contactIdNumber: {
    type: 'string',
    fieldType: 'text',
    label: 'Id Number',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Id Number is required',
      },
      {
        type: 'required',
        message: 'Id Number is required',
      },
    ],
  },
  contactEmail: {
    type: 'string',
    fieldType: 'text',
    label: 'Email',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'Email is to big',
      },
      {
        type: 'required',
        message: 'Email is required',
      },
      {
        type: 'email',
        message: 'Invalid email',
      },
    ],
  },
  contactphoneNumber: {
    type: 'string',
    fieldType: 'text',
    label: 'Phone',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'Phone is to big',
      },
      {
        type: 'required',
        message: 'Phone is required',
      },
    ],
  },
  contactProfession: {
    type: 'string',
    fieldType: 'text',
    label: 'Profession',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Profession is required',
      },
      {
        type: 'required',
        message: 'Profession is required',
      },
    ],
  },
  contactGender: {
    type: 'string',
    fieldType: 'select',
    label: 'Gender',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'required',
        message: 'is required',
      },
    ],
    options: [
      { id: 'MALE', name: 'Male' },
      { id: 'FEMALE', name: 'Female' },
    ],
  },
  contactMaritalStatus: {
    type: 'string',
    fieldType: 'text',
    label: 'Marital Status',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Marital status is required',
      },
      {
        type: 'required',
        message: 'Marital status is required',
      },
    ],
  },
  contactMothersName: {
    type: 'string',
    fieldType: 'text',
    label: "Mother's name",
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: "Mother's name is required",
      },
      {
        type: 'required',
        message: "Mother's name is required",
      },
    ],
  },
  contactSourceOfIncome: {
    type: 'string',
    fieldType: 'text',
    label: 'Source of income',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Source of income is required',
      },
      {
        type: 'required',
        message: 'Source of income is required',
      },
    ],
  },
  contactCitizenship: {
    type: 'string',
    fieldType: 'text',
    label: 'Place of Birth',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
  },
  contactPlaceOfBirth: {
    type: 'string',
    fieldType: 'text',
    label: 'Citizenship',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
  },
  contactStreet1: {
    type: 'string',
    fieldType: 'text',
    label: 'Street 1',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'is to big',
      },
      {
        type: 'required',
        message: 'Street is required',
      },
    ],
  },
  contactStreet2: {
    type: 'string',
    fieldType: 'text',
    label: 'Street 2',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'is to big',
      },
    ],
  },
  contactCity: {
    type: 'string',
    fieldType: 'text',
    label: 'City',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'is to big',
      },
    ],
  },
  contactState: {
    type: 'string',
    fieldType: 'text',
    label: 'State',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'is to big',
      },
      {
        type: 'required',
        message: 'is required',
      },
    ],
  },
  contactZipcode: {
    type: 'string',
    fieldType: 'text',
    label: 'Zipcode',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'is to big',
      },
      {
        type: 'required',
        message: 'is required',
      },
    ],
  },
  contactCountry: {
    type: 'string',
    fieldType: 'select',
    label: 'Country',
    parent: {
      fieldType: 'array',
      key: 'contacts',
    },
    constraints: [
      {
        type: 'required',
        message: 'is required',
      },
    ],
    value: null,
    onSearch: async search => {
      let query = search
      if (!isMongoId(search)) {
        query = `filterEq=name:${search}`
      }
      return listCountries(query, 0, 5, '').then(data => {
        return data.content
      })
    },
  },
}

export const BUSINESS_CONTACT_INITIAL_DATA = {
  contactFirstName: '',
  contactLastName: '',
  contactDateOfBirth: '',
  contactPlaceOfBirth: '',
  contactCitizenship: '',
  contactIdNumber: '',
  contactEmail: '',
  contactphoneNumber: '',
  contactProfession: '',
  contactGender: '',
  contactMaritalStatus: '',
  contactMothersName: '',
  contactSourceOfIncome: '',
  contactStreet1: '',
  contactStreet2: '',
  contactCity: '',
  contactState: '',
  contactZipcode: '',
  contactCountry: '',
}

const BENEFICIARY_DATA_SELECT_BENEFICIARY_TYPE = {
  type: {
    type: 'string',
    fieldType: 'select',
    label: 'Beneficiary Type',
    constraints: [
      {
        type: 'required',
        message: 'Beneficiary Type is required',
      },
    ],
    onSelected: () => {},
    options: [
      {
        id: BENEFICIARY_USER_TYPES.INDIVIDUAL.key,
        name: BENEFICIARY_USER_TYPES.INDIVIDUAL.label,
      },
      {
        id: BENEFICIARY_USER_TYPES.BUSINESS.key,
        name: BENEFICIARY_USER_TYPES.BUSINESS.label,
      },
    ],
  },
}

export const BENEFICIARY_DATA = {
  INDIVIDUAL: {
    ...BENEFICIARY_DATA_SELECT_BENEFICIARY_TYPE,
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Id',
      readOnly: true,
    },
    integratorBeneficiaryId: {
      type: 'string',
      fieldType: 'text',
      label: 'External ID #',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Too big',
        },
        {
          type: 'required',
          message: 'Is required',
        },
      ],
    },
    active: {
      type: 'string',
      fieldType: 'switch',
      label: 'Account Status',
    },
    firstName: {
      type: 'string',
      fieldType: 'text',
      label: 'First Name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'First Name is required',
        },
        {
          type: 'required',
          message: 'First Name is required',
        },
      ],
    },
    lastName: {
      type: 'string',
      fieldType: 'text',
      label: 'Last Name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Last Name is required',
        },
        {
          type: 'required',
          message: 'Last Name is required',
        },
      ],
    },
    placeOfBirth: {
      type: 'string',
      fieldType: 'text',
      label: 'Place of Birth',
    },
    citizenship: {
      type: 'string',
      fieldType: 'text',
      label: 'Citizenship',
    },
    dateOfBirth: {
      type: 'date',
      fieldType: 'pickdate',
      label: 'Date of Birth',
    },
    idNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Legal ID #',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'ID Number is required',
        },
        {
          type: 'required',
          message: 'ID Number is required',
        },
      ],
    },
    email: {
      type: 'string',
      fieldType: 'text',
      label: 'Email',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Email is to big',
        },
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Invalid email',
        },
      ],
    },
    phoneNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Phone is to big',
        },
        {
          type: 'required',
          message: 'Phone is required',
        },
      ],
    },
    profession: {
      type: 'string',
      fieldType: 'text',
      label: 'Profession',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Profession is required',
        },
        {
          type: 'required',
          message: 'Profession is required',
        },
      ],
    },
    gender: {
      type: 'string',
      fieldType: 'select',
      label: 'Gender',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'MALE', name: 'Male' },
        { id: 'FEMALE', name: 'Female' },
      ],
    },
    maritalStatus: {
      type: 'string',
      fieldType: 'text',
      label: 'Marital Status',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Marital status is required',
        },
        {
          type: 'required',
          message: 'Marital status is required',
        },
      ],
    },
    mothersName: {
      type: 'string',
      fieldType: 'text',
      label: "Mother's name",
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: "Mother's name is required",
        },
        {
          type: 'required',
          message: "Mother's name is required",
        },
      ],
    },
    street1: {
      type: 'string',
      fieldType: 'text',
      label: 'Street 1',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street is required',
        },
      ],
    },
    street2: {
      type: 'string',
      fieldType: 'text',
      label: 'Street 2',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
      ],
    },
    city: {
      type: 'string',
      fieldType: 'text',
      label: 'City',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'City is required',
        },
      ],
    },
    state: {
      type: 'string',
      fieldType: 'text',
      label: 'State',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    zipcode: {
      type: 'string',
      fieldType: 'text',
      label: 'Zipcode',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetBankAgencyNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank Agency Number',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetBankAccountNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank Account Number',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetBankName: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetAccountType: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank Account Type',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetAccountPixKey: {
      type: 'string',
      fieldType: 'text',
      label: 'Pix key',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    incomeSource: {
      type: 'string',
      fieldType: 'select',
      label: 'Income Source',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'salary', name: 'salary' },
        { id: 'selfEmployed', name: 'selfEmployed' },
        { id: 'inheritance', name: 'inheritance' },
        { id: 'investments', name: 'investments' },
        { id: 'retirement', name: 'retirement' },
        { id: 'rentalIncome', name: 'rentalIncome' },
        { id: 'governmentBenefits', name: 'governmentBenefits' },
        { id: 'familySupport', name: 'familySupport' },
      ],
    },
    industry: {
      type: 'string',
      fieldType: 'select',
      label: 'Industry',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'agriculture', name: 'agriculture' },
        { id: 'mining', name: 'mining' },
        { id: 'utilities', name: 'utilities' },
        { id: 'construction', name: 'construction' },
        { id: 'manufacturing', name: 'manufacturing' },
        { id: 'chemicalManufacturing', name: 'chemicalManufacturing' },
        { id: 'merchantWholesale', name: 'merchantWholesale' },
        { id: 'automotive', name: 'automotive' },
        { id: 'retailFurnishing', name: 'retailFurnishing' },
        { id: 'retailElectronics', name: 'retailElectronics' },
        { id: 'retailHome', name: 'retailHome' },
        { id: 'retailFood', name: 'retailFood' },
        { id: 'healthStores', name: 'healthStores' },
        { id: 'gasoline', name: 'gasoline' },
        { id: 'retailClothing', name: 'retailClothing' },
        { id: 'retailSporting', name: 'retailSporting' },
        { id: 'retailNonStore', name: 'retailNonStore' },
        { id: 'transportation', name: 'transportation' },
        { id: 'publishing', name: 'publishing' },
        { id: 'finance', name: 'finance' },
        { id: 'realEstate', name: 'realEstate' },
        { id: 'rentalServices', name: 'rentalServices' },
        { id: 'professionalNonLegal', name: 'professionalNonLegal' },
        { id: 'accounting', name: 'accounting' },
        { id: 'travel', name: 'travel' },
        { id: 'educationalMedical', name: 'educationalMedical' },
        { id: 'performingArts', name: 'performingArts' },
        { id: 'recreationGambling', name: 'recreationGambling' },
        { id: 'foodService', name: 'foodService' },
        { id: 'maintenance', name: 'maintenance' },
        { id: 'laundry', name: 'laundry' },
        { id: 'religiousCharity', name: 'religiousCharity' },
        { id: 'publicAdministration', name: 'publicAdministration' }
      ],
    },
    occupation: {
      type: 'string',
      fieldType: 'select',
      label: 'Occupation',
      options: [
        { id:'executive', name: 'executive' },
        { id:'sales', name: 'sales' },
        { id:'consulting', name: 'consulting' },
        { id:'government', name: 'government' },
        { id:'healthcare', name: 'healthcare' },
        { id:'financeAccounting', name: 'financeAccounting' },
        { id:'technology', name: 'technology' },
        { id:'scienceEngineering', name: 'scienceEngineering' },
        { id:'foodServices', name: 'foodServices' },
        { id:'legalServices', name: 'legalServices' },
        { id:'manufacturing', name: 'manufacturing' },
        { id:'other', name: 'other' },
      ],
    },
    wealthSource: {
      type: 'string',
      fieldType: 'select',
      label: 'Wealth Source',
      options: [
        { id: 'salary', name: 'salary' },
        { id: 'selfEmployed', name: 'selfEmployed' },
        { id: 'inheritance', name: 'inheritance' },
        { id: 'familySupport', name: 'familySupport' },
        { id: 'investments', name: 'investments' },
        { id: 'retirement', name: 'retirement' },
        { id: 'rentalIncome', name: 'rentalIncome' },
        { id: 'governmentBenefits', name: 'governmentBenefits' },
        { id: 'businessSale', name: 'businessSale' },
        { id: 'other', name: 'other' }
      ],
    },
    annualIncome: {
      type: 'string',
      fieldType: 'text',
      label: 'Annual Income',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
    }
  },
  BUSINESS: {
    ...BENEFICIARY_DATA_SELECT_BENEFICIARY_TYPE,
    // Account # - generated on backend
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Id',
      readOnly: true,
    },
    // Proof of ID (eg. SSN) - 3rd party ID, is not editable
    // but comes from their database.
    integratorBeneficiaryId: {
      type: 'string',
      fieldType: 'text',
      label: 'External ID #',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Too big',
        },
        {
          type: 'required',
          message: 'Is required',
        },
      ],
    },
    name: {
      type: 'string',
      fieldType: 'text',
      label: 'Name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 100,
          message: 'Name is required',
        },
        {
          type: 'required',
          message: 'Name is required',
        },
      ],
    },
    description: {
      type: 'string',
      fieldType: 'text',
      label: 'Description',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Description is Too Short!',
        },
        {
          type: 'max',
          max: 1000,
          message: 'Description is too long',
        },
        {
          type: 'required',
          message: 'Description is required',
        },
      ],
    },
    legalStructure: {
      type: 'string',
      fieldType: 'select',
      label: 'Legal Structure',
      constraints: [
        {
          type: 'required',
          message: 'Legal Structure is required',
        },
      ],
      onSelected: () => {},
      options: Object.keys(BENEFICIARY_BUSINESS_LEGAL_STRUCTURES).map(key => ({
        id: BENEFICIARY_BUSINESS_LEGAL_STRUCTURES[key].key,
        name: BENEFICIARY_BUSINESS_LEGAL_STRUCTURES[key].label,
      }))

    },
    dateOfIncorporation: {
      type: 'date',
      fieldType: 'pickdate',
      label: 'Date of Incorporation',
    },
    idNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Legal ID #',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Id Number is required',
        },
        {
          type: 'required',
          message: 'Id Number is required',
        },
      ],
    },
    email: {
      type: 'string',
      fieldType: 'text',
      label: 'Email',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Email is to big',
        },
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Invalid email',
        },
      ],
    },
    phoneNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Phone is to big',
        },
        {
          type: 'required',
          message: 'Phone is required',
        },
      ],
    },
    active: {
      type: 'string',
      fieldType: 'switch',
      label: 'Account Status',
    },
    street1: {
      type: 'string',
      fieldType: 'text',
      label: 'Street1',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street is required',
        },
      ],
    },
    street2: {
      type: 'string',
      fieldType: 'text',
      label: 'Street2',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
      ],
    },
    city: {
      type: 'string',
      fieldType: 'text',
      label: 'City',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'City is required',
        },
      ],
    },
    state: {
      type: 'string',
      fieldType: 'text',
      label: 'State',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    zipcode: {
      type: 'string',
      fieldType: 'text',
      label: 'Zipcode',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetBankAgencyNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank Agency Number',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetBankAccountNumber: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank Account Number',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetBankName: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetAccountType: {
      type: 'string',
      fieldType: 'text',
      label: 'Bank Account Type',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    localTargetAccountPixKey: {
      type: 'string',
      fieldType: 'text',
      label: 'Pix key',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    contacts: {
      type: 'array',
      label: 'Contact',
      constraints: [
        {
          type: 'min',
          min: 1,
          message: 'At least 1 contact must be added',
        },
        {
          type: 'required',
          message: 'Contact is required',
        },
      ],
      valueSchema: BUSINESS_BENEFICIARY_CONTACT_FIELDS,
    },
  },
}

export const getBeneficiaryDataFields = type => BENEFICIARY_DATA[type]

// Transform form data for saving
export const mapBusinessRequest = data => {
  return {
    integratorId: data.integratorId,
    integratorBeneficiaryId: data.integratorBeneficiaryId,
    type: data.type,
    active: data.active,
    business: {
      name: data.name,
      dateOfIncorporation: formatDate(data.dateOfIncorporation),
      idNumber: data.idNumber,
      email: data.email,
      phoneNumber: data.phoneNumber,
      address: {
        streetOne: data.street1,
        streetTwo: data.street2,
        city: data.city,
        zipcode: data.zipcode,
        state: data.state,
      },
      description: data.description,
      legalStructure: data.legalStructure,
      contacts: data.contacts.map(contact => ({
        firstName: contact.contactFirstName,
        lastName: contact.contactLastName,
        dateOfBirth: formatDate(contact.contactDateOfBirth),
        placeOfBirth: contact.contactPlaceOfBirth,
        citizenship: contact.contactCitizenship,
        idNumber: contact.contactIdNumber,
        email: contact.contactEmail,
        phoneNumber: contact.contactphoneNumber,
        profession: contact.contactProfession,
        gender: contact.contactGender,
        maritalStatus: contact.contactMaritalStatus,
        mothersName: contact.contactMothersName,
        sourceOfIncome: contact.contactSourceOfIncome,
        address: {
          streetOne: contact.contactStreet1,
          streetTwo: contact.contactStreet2,
          city: contact.contactCity,
          zipcode: contact.contactZipcode,
          state: contact.contactState,
          country: contact.contactCountry,
        },
      })),
    },
    localTargetBankAccount: {
      accountDetails: {
        accountAgencyNumber: data.localTargetBankAgencyNumber,
        accountNumber: data.localTargetBankAccountNumber,
        bankName: data.localTargetBankName,
        accountType: data.localTargetAccountType,
        pixKey: data.localTargetAccountPixKey,
      }
    },
  }
}

export const createInitialDataForBusinessBeneficiary = ({
  integratorId,
  type,
}) => {
  return {
    type,
    integratorId,
    integratorBeneficiaryId: '',
    name: '',
    active: true,
    dateOfIncorporation: formatDate(new Date()),
    idNumber: '',
    email: '',
    legalStructure: '',
    description: '',
    phoneNumber: '',
    streetOne: '',
    streetTwo: '',
    city: '',
    zipcode: '',
    state: '',
    contacts: [BUSINESS_CONTACT_INITIAL_DATA],
  }
}

export const mapFromBusinessRequest = data => {
  return {
    integratorId: data.integratorId,
    integratorBeneficiaryId: data.integratorBeneficiaryId,
    type: data.type,
    active: data.active,
    kycVerified: data.kycVerified,
    name: data.business.name,
    dateOfIncorporation: data.business.dateOfIncorporation,
    idNumber: data.business.idNumber,
    legalStructure: data.business.legalStructure,
    description: data.business.description,
    email: data.business.email,
    phoneNumber: data.business.phoneNumber,
    street1: data.business.address.streetOne,
    street2: data.business.address.streetTwo,
    city: data.business.address.city,
    zipcode: data.business.address.zipcode,
    state: data.business.address.state,
    localTargetBankAgencyNumber: (data.localTargetBankAccount && data.localTargetBankAccount.accountDetails.accountAgency) || '',
    localTargetBankAccountNumber: (data.localTargetBankAccount && data.localTargetBankAccount.accountDetails.accountNumber)||'',
    localTargetBankName: (data.localTargetBankAccount && data.localTargetBankAccount.accountDetails.bankName)||'',
    localTargetAccountType: (data.localTargetBankAccount && data.localTargetBankAccount.accountDetails.accountType)||'',
    localTargetAccountPixKey: (data.localTargetBankAccount && data.localTargetBankAccount.accountDetails.pixKey)||'',
    contacts: data.business.contacts.map(contact => ({
      contactFirstName: contact.firstName,
      contactLastName: contact.lastName,
      contactDateOfBirth: new Date(contact.dateOfBirth),
      contactPlaceOfBirth: contact.placeOfBirth,
      contactCitizenship: contact.citizenship,
      contactIdNumber: contact.idNumber,
      contactEmail: contact.email,
      contactphoneNumber: contact.phoneNumber,
      contactProfession: contact.profession,
      contactGender: contact.gender,
      maritalStatus: contact.maritalStatus,
      mothersName: contact.mothersName,
      sourceOfIncome: contact.sourceOfIncome,
      contactStreet1: contact.address.streetOne,
      contactStreet2: contact.address.streetTwo,
      contactCity: contact.address.city,
      contactZipcode: contact.address.zipcode,
      contactState: contact.address.state,
      contactCountry: contact.address.country,
    })),
    createdDate: data.createdDate
  }
}

export const mapIndividualRequest = data => {
  return {
    integratorId: data.integratorId,
    integratorBeneficiaryId: data.integratorBeneficiaryId,
    type: data.type,
    active: data.active,
    person: {
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: formatDate(data.dateOfBirth),
      placeOfBirth: data.placeOfBirth,
      citizenship: data.citizenship,
      idNumber: data.idNumber,
      email: data.email,
      phoneNumber: data.phoneNumber,
      profession: data.profession,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      mothersName: data.mothersName,
      incomeSource: data.incomeSource,
      industry: data.industry,
      occupation: data.occupation,
      wealthSource: data.wealthSource,
      annualIncome: data.annualIncome,
      address: {
        streetOne: data.street1,
        streetTwo: data.street2,
        city: data.city,
        zipcode: data.zipcode,
        state: data.state,
      },
    },
    localTargetBankAccount: {
      accountDetails: {
        accountAgency: data.localTargetBankAgencyNumber,
        accountNumber: data.localTargetBankAccountNumber,
        bankName: data.localTargetBankName,
        accountType: data.localTargetAccountType,
        pixKey: data.localTargetAccountPixKey,
      }
    },
  }
}

export const createInitialDataForIndividualBeneficiary = ({
  integratorId,
  type,
}) => {
  return {
    integratorId,
    integratorBeneficiaryId: '',
    type,
    active: true,
    firstName: '',
    lastName: '',
    dateOfBirth: formatDate(new Date()),
    placeOfBirth: '',
    idNumber: '',
    email: '',
    phoneNumber: '',
    citizenship: '',
    profession: '',
    gender: '',
    maritalStatus: '',
    mothersName: '',
    sourceOfIncome: '',
    street1: '',
    street2: '',
    city: '',
    zipcode: '',
    state: '',
  }
}

export const mapFromIndividualRequest = data => {
  console.log(`${JSON.stringify(data)}`)

  data.localTargetBankAccount = data.localTargetBankAccount || {}

  return {
    id: data.id,
    integratorId: data.integratorId,
    integratorBeneficiaryId: data.integratorBeneficiaryId,
    type: data.type,
    status: data.status,
    active: data.active,
    kycVerified: data.kycVerified,
    firstName: data.person.firstName,
    lastName: data.person.lastName,
    dateOfBirth: data.person.dateOfBirth,
    placeOfBirth: data.person.placeOfBirth,
    citizenship: data.person.citizenship,
    idNumber: data.person.idNumber,
    email: data.person.email,
    phoneNumber: data.person.phoneNumber,
    profession: data.person.profession,
    gender: data.person.gender,
    maritalStatus: data.person.maritalStatus,
    mothersName: data.person.mothersName,
    incomeSource: data.person.incomeSource,
    industry: data.person.industry,
    occupation: data.person.occupation,
    wealthSource: data.person.wealthSource,
    annualIncome: data.person.annualIncome,
    street1: data.person.address.streetOne,
    street2: data.person.address.streetTwo,
    city: data.person.address.city,
    zipcode: data.person.address.zipcode,
    state: data.person.address.state,
    localTargetBankAgencyNumber: data.localTargetBankAccount.agency ?? '',
    localTargetBankAccountNumber: data.localTargetBankAccount.accountNumber ?? '',
    localTargetBankName: data.localTargetBankAccount.bankName ?? '',
    localTargetAccountType: data.localTargetBankAccount.accountType ?? '',
    localTargetAccountPixKey: data.localTargetBankAccount.pixKey ?? '',
    createdDate: data.createdDate,
    riskScore: data?.riskScore?.riskScore || '',
    riskRating: data?.riskScore?.riskRating || '',
    riskScoreVersion: data?.riskScore?.riskScoreVersion || '',
    riskScoreCalculatedAt: data?.riskScore?.calculatedAt || '',
    riskScoreSnapshotId: data?.riskScore?.riskScoreSnapshot || ''
  }
}

const steps = [
  {
    label: 'Basic Info',
    fields: CREATE_BENEFICIARY_STEPS.shared[0],
  },
  {
    label: 'Address',
    fields: CREATE_BENEFICIARY_STEPS.shared[1],
  },
  {
    label: 'Account Info',
    fields: CREATE_BENEFICIARY_STEPS.shared[2],
  },
]

export const getCreateBeneficiarySteps = ({
  profileType,
  isAdmin = false,
}) => {
  const isIndividual =
    profileType === BENEFICIARY_USER_TYPES.INDIVIDUAL.key
  // Iterate through base steps and update fields based on beneficiary
  // type.


  const stepsClone = steps.map(({ label, fields }, index) => {

    return {
      label,
      fields: [
        ...fields,
        ...CREATE_BENEFICIARY_STEPS[profileType][index],
      ],
    }
  })

  // If Business, Add Step 3
  if (!isIndividual) {


    stepsClone.push({
      label: 'Company Contact',
      fields: CREATE_BENEFICIARY_STEPS[profileType][2],
    })

  }

  // If Admin, add integratorId field to Step 1
  if (isAdmin) {
    stepsClone[0].fields = [...stepsClone[0].fields, 'integratorId']
  }

  return stepsClone
}

function formatDate(date) {
  if(!date) {
    return ""
  }

  let dateObj = date;
  if(Object.prototype.toString.call(date) !== '[object Date]') {
    dateObj = new Date(date);
  }

  const month = (dateObj.getMonth()+1) < 10 ? ("0" + (dateObj.getMonth()+1)) : (dateObj.getMonth()+1);
  const day = dateObj.getDate() < 10 ? ("0" + dateObj.getDate()) : dateObj.getDate();

  return dateObj.getFullYear() + "-" + (month) + "-" + day;
}

const helpers = {
  mapBusinessRequest,
  mapFromBusinessRequest,
  mapIndividualRequest,
  mapFromIndividualRequest,
  getCreateBeneficiarySteps,
}

export default helpers

